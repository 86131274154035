import actions from './Notification.actions';

export const notificationActionCreators = {
  startShowNotification: (message) => (dispatch) => {
    const time = new Date();
    dispatch({
      type: actions.NOTIFICATION_SHOW,
      message,
      timestamp: time.toLocaleTimeString('en-US')
    });
  },
  endShowNotification: () => (dispatch) => {
    dispatch({ type: actions.NOTIFICATION_HIDE });
  },
  dismissNotification: (timestamp) => (dispatch) => {
    dispatch({ type: actions.NOTIFICATION_DISMISS, timestamp });
  },
  dismissNotifications: () => (dispatch) => {
    dispatch({ type: actions.NOTIFICATION_DISMISSALL, newCount: 0 });
  },
  deleteAllNotifications: () => (dispatch) => {
    dispatch({ type: actions.NOTIFICATION_DELETEALL });
  }
};

export default notificationActionCreators;
