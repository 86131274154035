import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as reduxFormReducer } from 'redux-form';

/**
 * Component reducers
 */
import { reducer as community } from '../controllers/CommunityController/Community.reducers';
import { reducer as progress } from '../tabs/components/Progress/Progress.reducers';
import { reducer as auth } from "../contexts/Auth/auth.reducers"

const reducers = {
  community,
  form: reduxFormReducer,
  progress,
  auth
};

const rootReducer = combineReducers({
  ...reducers,
  routing: routerReducer
});

export default rootReducer;

