import { push } from 'react-router-redux';
import { pages } from '@microsoft/teams-js';

import { notificationActionCreators } from '../../tabs/components/Notification';
import { progressActionCreators } from '../../tabs/components/Progress';
import * as constants from '../../common/Constants';
import {
    ICommunity,
    CommunityType,
    IGovTEAMSProfileInfo,
} from './Community.types';
import actions from './Community.actions';
import { getGTApiToken } from "../../common/auth";
import config from '../../config';

export const communityActionCreators = {
    setFormIsDirty: () => (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_FORM_DIRTY
        });
    },

    acceptedTnCCommunity: () => async (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_TNC_ACCEPTED
        });
    },

    startEditManage: (community) => async (dispatch, getState) => {
        if (community === null) {
            dispatch({
                type: actions.COMMUNITY_EDIT_START_CREATE
            });
            dispatch(push(constants.SCREEN_COMMUNITY));
        } else {
            dispatch({
                type: actions.COMMUNITY_MANAGE_START,
                communityId: community.communityId,
                initialValues: community
            });
            dispatch(
                push(`${constants.SCREEN_COMMUNITY}/${community.communityAzureId}`)
            );
        }
    },
    
     endEditCommunity: (communityId?: string) => async (dispatch, getState) => {
         dispatch(push(constants.SCREEN_DASHBOARD));
         try {
             await pages.currentApp.navigateToDefaultPage();
         }
         catch (e) {
             console.error('Failed to navigate to default page', e);
         }
     },

    /**
     * Fetch user profile
     */
    requestFetchProfile: (apiToken: string = '') => async (
        dispatch,
        getState
    ) => {
        dispatch(progressActionCreators.startShowProgress('Fetching profile'));

        let _apiToken: string = apiToken;

        try {
            if ( _apiToken === '' ) {
                _apiToken = await getGTApiToken(getState().auth.teamsToken)
            }
            const response: any = await fetch(
                config.apiBaseUrl + '/profiles',
                {
                    headers: {
                        Authorization: 'Bearer ' + _apiToken,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const govTeamsProfileInfo: IGovTEAMSProfileInfo = await response.json();

            // const msGraphUrl = 'https://graph.microsoft.com/v1.0/me?$select=id,mail,displayName,userPrincipalName,jobTitle,employer,department,businessPhones,mobilePhone,givenName';
            // const msResponseJson: any = await fetch(
            //     msGraphUrl,
            //     {
            //         headers: {
            //             Authorization: 'Bearer ' + _apiToken,
            //             'Content-Type': 'application/json'
            //         }
            //     }
            // );
            // const msResponse = await msResponseJson.json();
            // console.log('msResponse', msResponse);

            dispatch({
                type: actions.PROFILE_GET_GOVTEAMS_PROFILE,
                govTeamsProfileInfo,
            });
            dispatch(progressActionCreators.endShowProgress());
        } catch (error) {
            dispatch(
                notificationActionCreators.startShowNotification(
                    constants.ERROR_PROFILE_FETCH
                )
            );
            dispatch({ type: actions.PROFILE_GET_GOVTEAMS_PROFILE_ERROR, error });
            dispatch(progressActionCreators.endShowProgress());
        }
    },

    /**
     * Fetch community using Azure GUID
     */
     requestFetchCommunity: (communityAzureId, loadTags: boolean = false) => async (
         dispatch,
         getState
     ) => {
         if (getState().community.isLoading) {
             return;
         }
    
         dispatch({ type: actions.COMMUNITY_FETCH_REQUEST });
         dispatch(progressActionCreators.startShowProgress('Fetching community'));
    
         const apiToken: string = await getGTApiToken(
             getState().auth.teamsToken
         );
    
         try {
             const response: any = await fetch(
                 config.apiBaseUrl + `/communities/${communityAzureId}`,
                 {
                     headers: {
                         Authorization: 'Bearer ' + apiToken,
                         'Content-Type': 'application/json'
                     }
                 }
             );
    
             const initialValues: ICommunity = await response.json();
    
             /**
              * Subsequent request to use internal ID
              */
             if (loadTags) {
                 const tagResponse: any = await fetch(
                     config.apiBaseUrl + `/communities/${initialValues.communityId}/tags`,
                     {
                         headers: {
                             Authorization: 'Bearer ' + apiToken,
                             'Content-Type': 'application/json'
                         }
                     }
                 );
    
                 const tags: any = await tagResponse.json();
                 initialValues.tags = tags;
             }
    
             dispatch({
                 type: actions.COMMUNITY_FETCH_RESPONSE,
                 communityId: initialValues.communityId,
                 initialValues
             });
             dispatch(progressActionCreators.endShowProgress());
         } catch (error) {
             dispatch(
                 notificationActionCreators.startShowNotification(
                     constants.ERROR_COMMUNITY_FETCH
                 )
             );
             dispatch({ type: actions.COMMUNITY_FETCH_RESPONSE, error });
             dispatch(progressActionCreators.endShowProgress());
         }
     },


    startJoinMember: (communityId, communityType, isManagePage, communityName = '') => async (
        dispatch,
        getState
    ) => {
        console.log('startJoinMember', communityId);

        if (communityId) {
            dispatch(
                progressActionCreators.startShowProgress('Joining the Community...')
            );

            try {
                const apiToken: string = await getGTApiToken(
                    getState().auth.teamsToken
                );

                console.log('Sending request to join to API');
                await fetch(
                    config.apiBaseUrl + `/requests/${communityId}`,
                    {
                        method: 'POST',
                        body: JSON.stringify({}),
                        headers: {
                            Authorization: 'Bearer ' + apiToken,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                dispatch(progressActionCreators.startShowProgress('Request sent successfully'));

                // if (communityType === 'Open') {
                //     dispatch(
                //         communityActionCreators.updateMemberCommunities(communityId)
                //     );
                //     dispatch(
                //         communityActionCreators.requestFetchCommunity(communityId)
                //     );
                // }
            } catch (error) {
                dispatch({
                    type: actions.COMMUNITY_JOIN_REQUEST,
                    isSubmitted: false,
                    isDirty: true,
                    error: error
                });
            }
            finally {
                setTimeout(() => dispatch(progressActionCreators.endShowProgress()), 3000);
            }
        }
        else {
            dispatch({
                type: actions.COMMUNITY_JOIN_REQUEST,
                isSubmitted: false,
                isDirty: true,
                error: { error: 'Community ID is empty' },
            });
        }
    },

    requestSaveCommunity: (communityId) => async (dispatch, getState) => {
        if (getState().community.isLoading) {
            return;
        }
        
        dispatch({ type: actions.COMMUNITY_SAVE_REQUEST });
        dispatch(progressActionCreators.startShowProgress('Saving community'));

        const communityForm = getState().form.CommunityForm.values;

        const apiToken: string = await getGTApiToken(
            getState().auth.teamsToken
        );

        if (communityId) {
            try {
                const response = await fetch(
                    config.apiBaseUrl + `/communities/${communityId}`,
                    {
                        method: 'put',
                        body: JSON.stringify(communityForm),
                        headers: {
                            Authorization: 'Bearer ' + apiToken,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (!response.ok) {
                    throw response;
                }
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: true,
                    isDirty: true,
                    error: ''
                });
                /**
                 * To do: check reinitialisation after saving
                 *
                 */
                const existingValues: ICommunity = getState().community.initialValues;
                const initialValues: ICommunity = {
                    communityAzureId: existingValues.communityAzureId,
                    communityId: existingValues.communityId,
                    communityType: CommunityType[communityForm.typeId],
                    iconPreferenceId: communityForm.iconPreferenceId,
                    isCreated: existingValues.isCreated,
                    isFavourite: existingValues.isFavourite,
                    isMember: existingValues.isMember,
                    isOwner: existingValues.isOwner,
                    communityMembershipId: existingValues.communityMembershipId,
                    displayName: communityForm.displayName,
                    url: existingValues.url,
                    totalMembers: existingValues.totalMembers,
                    pendingRequests: existingValues.pendingRequests,
                    description: communityForm.description,
                    extendedDescription: communityForm.extendedDescription,
                    sharePointUrl: existingValues.sharePointUrl,
                    sponsored: existingValues.sponsored,
                    tags: []
                };

                communityForm.tags.forEach((tag: any) => {
                    initialValues.tags.push('#' + tag.name);
                });

                dispatch({
                    type: actions.COMMUNITY_MANAGE_START,
                    communityId,
                    initialValues
                });
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: true,
                    isDirty: false
                });
                //dispatch(communityActionCreators.endEditCommunity(communityId));
                // dispatch(
                //     notificationActionCreators.startShowNotification(
                //         MESSAGE_COMMUNITY_SAVE
                //     )
                // );
            } catch (response) {
                const json = await response.json();
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: false,
                    isDirty: true,
                    error: json
                });
                // dispatch(
                //     notificationActionCreators.startShowNotification(
                //         MESSAGE_COMMUNITY_SAVE_ERROR.replace(
                //             '%DISPLAYNAME%',
                //             getState().community.initialValues.displayName
                //         ).replace('%ERROR%', json)
                //     )
                // );
            }

            dispatch(progressActionCreators.endShowProgress());
        } else {
            dispatch(
                progressActionCreators.startShowProgress('Creating new community...')
            );

            try {
                const response = await fetch(config.apiBaseUrl + `/communities`, {
                    method: 'post',
                    body: JSON.stringify(communityForm),
                    headers: {
                        Authorization: 'Bearer ' + apiToken,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw response;
                }
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: true,
                    isDirty: true,
                    error: ''
                });
                dispatch(progressActionCreators.endShowProgress());
            } catch (error) {
                const json = await error.json();
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: false,
                    isDirty: true,
                    error: json
                });
                dispatch(progressActionCreators.endShowProgress());
            }
        }
    },

    requestSimilarCommunities: (apiToken?: string) => async (dispatch, getState) => {
         //if (getState().profile.isGuest) {
         //    return;
         //}

        if (getState().community.isLoading) {
            return;
        }

        const communityForm = getState().form.CommunityForm.values;

        //if (!communityForm) {
        //    return;
        //}

        const displayName = communityForm.displayName
            ? communityForm.displayName
            : '';
        let tags = '';
        const similarCommunities: any[] = [];

        if (communityForm.tags && communityForm.tags.length > 0) {
            communityForm.tags.forEach((tag) => {
                tags += tag.name + ';';
            });
        }

        dispatch({ type: actions.SIMILARCOMMUNITIES_FETCH_REQUEST });

        let _apiToken = apiToken;

        try {
            if ( !apiToken ) {
                _apiToken = await getGTApiToken(
                    getState().auth.teamsToken
                );
            }

            const response = await fetch(
                config.apiBaseUrl +
                `/communities/Similar?filter=${displayName}&projects=${tags}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + _apiToken,
                        'Content-Type': 'application/json'
                    }
                }
            );

            const communities = await response.json();

            if (communities && communities.length > 0) {
                for (let i = 0; i < communities.length && i < 3; i++) {
                    const communityData: any = communities[i];
                    similarCommunities.push(communityData);
                }
            }

            dispatch({
                type: actions.SIMILARCOMMUNITIES_FETCH_RESPONSE,
                similarCommunities
            });
            dispatch(progressActionCreators.endShowProgress());
        } catch (error) {
            // dispatch(
            //     notificationActionCreators.startShowNotification(
            //         constants.ERROR_SUGGESTEDCOMMUNITIES_FETCH
            //     )
            // );
            dispatch({
                type: actions.SIMILARCOMMUNITIES_FETCH_RESPONSE,
                error
            });
            dispatch(progressActionCreators.endShowProgress());
        }
    },

    requestFetchSuggestedCommunity: () => async (dispatch, getState) => {
        // if (getState().profile.isGuest) {
        //     return;
        // }

        if (getState().community.isSuggestedLoading) {
            return;
        }

        dispatch({ type: actions.SUGGESTEDCOMMUNITY_FETCH_REQUEST });
        const suggestedCommunities: any[] = [];
        /*
             Suggested Communities were disabled on 11/7/2019.  Uncomment these sections to enable suggested communities again.
             */
        //const skills = getState().profile.skills ? getState().profile.skills : '';
        //const interests = getState().profile.interests
        //  ? getState().profile.interests
        //  : '';
        //const projects = getState().profile.projects
        //  ? getState().profile.projects
        //  : '';

        //const apiToken: string = await adalGetToken(
        //  authContext,
        //  adalConfig.endpoints.api.guid
        //);
        try {
            //const response = await fetch(
            //  API_URL() +
            //    `/communities/Suggested?skills=${skills}&interests=${interests}&projects=${projects}`,
            //  {
            //    headers: {
            //      Authorization: 'Bearer ' + apiToken,
            //      'Content-Type': 'application/json'
            //    }
            //  }
            //);
            //const communities = await response.json();

            //if (communities && communities.length > 1) {
            //  communities.forEach(community => {
            //    if (!community.isMember && !community.isOwner) {
            //      suggestedCommunities.push(community);
            //    }
            //  });
            //}

            dispatch({
                type: actions.SUGGESTEDCOMMUNITY_FETCH_RESPONSE,
                suggestedCommunities
            });
            dispatch(progressActionCreators.endShowProgress());
        } catch (error) {
            // dispatch(
            //     notificationActionCreators.startShowNotification(
            //         constants.ERROR_SUGGESTEDCOMMUNITIES_FETCH
            //     )
            // );
            dispatch({
                type: actions.SUGGESTEDCOMMUNITY_FETCH_RESPONSE,
                error
            });
            dispatch(progressActionCreators.endShowProgress());
        }
    },

    closeCommunityPanel: () => (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_PANEL_VIEW_END
        });
    }
};

//

export default communityActionCreators;
