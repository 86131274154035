const config: any = {
    publicHostname: process.env.REACT_APP_PUBLIC_HOSTNAME,
    applicationId: process.env.REACT_APP_APPLICATION_ID,
    packageName: process.env.REACT_APP_PACKAGE_NAME,
    tabAppId: process.env.REACT_APP_TAB_APP_ID,
    tabAppUri: process.env.REACT_APP_TAB_APP_URI,
    isProtected: process.env.REACT_APP_ISPROTECTED,
    clientId: process.env.REACT_APP_CLIENT_ID,
    scopes: process.env.REACT_APP_SCOPES,
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
};
export default config;