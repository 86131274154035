import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeIcons } from '@fluentui/react';

// import reportWebVitals from './reportWebVitals';
import { CreateCommunityTab } from './tabs/CreateCommunityTab';

import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

initializeIcons();

root.render(
  <React.Fragment>
    <CreateCommunityTab />
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
