//import actions from "../../controllers/CommunityController/Community.actions";
//import {initialState} from "../../controllers/CommunityController/Community.reducers";

export const actions = {
    SETTEAMSTOKEN: 'SETTEAMSTOKEN',
};

export const initialState = {
    teamsToken: ""
};

export const storeToken = (token) => ({
    type: actions.SETTEAMSTOKEN,
    payload: token,
});

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === actions.SETTEAMSTOKEN) {
        return {
            ...state,
            teamsToken: action.payload
        };
    }

    return state;
};

export default reducer;