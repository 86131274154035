/**
 * Community panel
 *
 * A summarised view of a community management screen that is accessible from community cards, list and search items
 *
 * Displays:
 * -Community information
 * -Owners
 * -Tools
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { communityActionCreators } from '../../../controllers/CommunityController';

import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Icon } from '@fluentui/react/lib/Icon';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DirectionalHint } from '@fluentui/react/lib/Callout';

/**
 * Child components
 */
//import CommunityTools from './../CommunityTools';
//import Owners from '../../screens/CommunityManagement/components/Owners';
import Tag from '../ReduxFormFields/Tag';

class CommunityPanel extends React.Component<any, any> {

  public render() {
    return (
      <Panel
        className='govTeams-communityPanel'
        isOpen={this.props.isCommunityPanelOpen}
        hasCloseButton={true}
        isBlocking={true}
        isLightDismiss={true}
        type={PanelType.custom}
        customWidth={'400px'}
        onDismissed={this.onPanelDismiss}
        onRenderBody={this.onRenderBody}
      />
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    /**
     * Dismiss panel when membership has changed
     */
    if (this.props.initialValues && nextProps.initialValues) {
      if (
        this.props.initialValues.communityId ===
          nextProps.initialValues.communityId &&
        this.props.initialValues.isMember !== nextProps.initialValues.isMember
      ) {
        this.onPanelDismiss();
      }
    }
  }

  private onPanelDismiss = () => {
    this.props.communityActionCreators.closeCommunityPanel();
  };

  private getTags = (community) => {
    const tags = community.tags;

    return (
      <div className='tags'>
        {tags.map((tag, idx) => {
          return <Tag tag={tag} key={idx} />;
        })}
      </div>
    );
  };

  private onRenderBody = () => {
    const { initialValues } = this.props;

    return (
      <React.Fragment>
        {initialValues && (
          <div className='panelBody'>
            <h2>{initialValues.displayName}</h2>
            <table className='communityInfo'>
              <tbody>
                <tr>
                  <td>
                    <Icon iconName='People' />
                  </td>
                  <td>
                    <TooltipHost
                      content={
                        'This community has ' +
                        initialValues.totalMembers +
                        ' members'
                      }
                      directionalHint={DirectionalHint.rightTopEdge}
                    >
                      {initialValues.totalMembers} Members
                    </TooltipHost>
                  </td>
                </tr>
                <tr>
                  <td>
                    {initialValues.communityType !== 'Hidden' ? (
                      <Icon
                        iconName={
                          initialValues.communityType === 'Open'
                            ? 'Unlock'
                            : 'Lock'
                        }
                      />
                    ) : (
                      <Icon iconName='Hide' />
                    )}
                  </td>
                  <td>
                    <TooltipHost
                      content={
                        'This community is ' + initialValues.communityType
                      }
                      directionalHint={DirectionalHint.rightTopEdge}
                    >
                      {initialValues.communityType}
                    </TooltipHost>
                  </td>
                </tr>
                {initialValues.tags.length > 0 && (
                  <tr>
                    <td>
                      <Icon iconName='Tag' />
                    </td>
                    <td>{this.getTags(initialValues)}</td>
                  </tr>
                )}
                {/* <tr>
                <td><div className="counter color-green">5</div></td><td>Current activity</td>
              </tr> */}
                {initialValues.isOwner && initialValues.pendingRequests > 0 && (
                  <tr>
                    <td>
                      <div className='counter'>
                        {initialValues.pendingRequests.toString()}
                      </div>
                    </td>
                    <td>Request count</td>
                  </tr>
                )}
              </tbody>
            </table>
            {initialValues.description && (
              <div>
                <h3>About</h3>
                <p>{initialValues.description}</p>
                <hr />
              </div>
            )}

            <h3>Owners</h3>
            {/*<Owners community={initialValues} />*/}
            <hr />

            {initialValues.isCreated && <h3>Community tools</h3>}
            {/*<CommunityTools*/}
            {/*  showManagementTools={true}*/}
            {/*  panelDismiss={this.onPanelDismiss}*/}
            {/*/>*/}
          </div>
        )}
      </React.Fragment>
    );
  };
}

const mapStateToProps = (state) => ({
  initialValues: state.community.initialValues,
  isCommunityPanelOpen: state.community.isCommunityPanelOpen
});

const mapDispatchToProps = (dispatch) => ({
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunityPanel);
