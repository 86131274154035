import { stopWatchDog } from './Progress.operations';
import { actions } from './Progress.actions';

const initialState = {
  label: '',
  isLoading: false,
  isBlocking: true
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case actions.SHOW_START:
      return {
        ...state,
        label: action.label,
        isLoading: true,
        isBlocking: action.isBlocking
      };

    case actions.SHOW_END:
      return {
        ...state,
        isLoading: false
      };

    case actions.WATCHDOG_TRIGGER:
      if (state.isLoading) {
        return {
          ...state,
          label: action.label
        };
      } else {
        return stopWatchDog();
      }

    default:
      return state;
  }
};

export default reducer;
