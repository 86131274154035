//import { CommunityGridSortOrder } from './../../screens/Dashboard/components/Communities/CommunityGridSorter';



export interface IDepartmentInfo {
  enabled: boolean;
  id: number;
  name: string;
}

export interface IGovTEAMSProfileInfo {
  daysLeftToVerify: number;
  department: IDepartmentInfo;
  emailAddress: string;
  employer: string | null;
  firstName: string;
  lastName: string;
  linkedAccountAzureId: string | null;
  memberStatus: MemberStatus;
  memberTypeId: number;
  memberType: MemberType;
  mobile: string;
  occupation: string;
  organisationDepartmentId: number | null,
  organisationContact: string;
  pendingEmailAddress: string;
  phone: string;
  positionLevel: string;
  licenceTempRemovedDisplayModal: boolean;
}

export interface ICommunityState {
  communityId: string | null;
  initialValues: ICommunity | any;
  ownerCommunities: ICommunity[] | null;
  memberCommunities: ICommunity[] | null;
  favouriteCommunities: ICommunity[] | null;
  suggestedCommunities: ICommunity[] | null;
  similarCommunities: ICommunity[] | null;
  isTnCAccepted: boolean;
  isSubmitted: boolean;
  isDirty: boolean;
  isFormDirty: boolean;
  isLoading: boolean;
  isTeamMemberLoading: boolean;
  isCommunityPanelOpen: boolean;
  isEditModalOpen: boolean;
  error: any | undefined;
  isSuggestedLoading: boolean;
  communityAzureId: string | null;
  memberTeams: any;
  news: any;
  profile: IGovTEAMSProfileInfo | null;
}

export interface ICommunityProps {
  communityActionCreators: any;
  dispatch: any;
}

export interface ICommunity {
  communityAzureId: string;
  communityId: number;
  communityType: string;
  description: string;
  extendedDescription?: string;
  displayName: string;
  iconPreferenceId: number;
  isCreated: boolean;
  isFavourite: boolean;
  isMember: boolean;
  isOwner: boolean;
  communityMembershipId: number;
  pendingRequests: number;
  sharePointUrl: string;
  sponsored: boolean;
  tags: string[];
  totalMembers: number;
  url: string;
}

export enum CommunityType {
  Open = 1,
  Private = 2,
  Hidden = 3
}

// export interface CommunityRequestOptions {
//   gridSortOrder:CommunityGridSortOrder;
// }

export enum CommunityIconPreferenceId {
  SharePointAndTeams = 1,
  Teams = 2,
  SharePoint = 3
}

export interface ICommunityMember {
  accountType: string;
  azureId: string;
  department: string;
  emailAddress: string;
  fullName: string;
  inactiveDays: number;
  isGuest: boolean;
  linkedAzureId: string | null;
  memberStatus: MemberStatus;
  memberType: MemberType;
  occupation: string;
  role: CommunityRole;
}

export enum CommunityRole {
  Owner = 'Owner',
  Member = 'Member'
}

export enum MemberType {
  Unassigned = 'Unassigned',
  Guest = 'Guest',
  FAM = 'Full Access Member',
  OrganisationAdmin = 'Organisation Admin',
  StateGuest = 'State Guest',
  StateLicensedAccount = 'State Licensed Account',
  LinkedGuestUser = 'Linked Guest User',
  Sponsored = 'Sponsored',
  LAM = 'Limited Access Member'
}

export enum MemberStatus {
  VerificationPending = 'Verification Pending',
  Active = 'Active',
  UnderReview = 'Under Review',
  Inactive = 'Inactive',
  Suspended = 'Suspended',
  Blocked = 'Blocked',
  PendingLicence = 'Pending Licence',
  PendingLicenceRemoval = 'Pending Licence Removal',
  PendingDeactivation = 'Pending Deactivation',
  PendingLicenceAssignment = 'Pending Licence Assignment',
  ApprovalPending = 'Approval Pending',
  ApprovalDeclined = 'Approval Declined'
}
