import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const enhancers = [];
const isDevelopment = process.env.NODE_ENV === 'development';

const composeEnhancer: typeof compose =
    (isDevelopment &&
        typeof window !== 'undefined' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const configureStore = history => createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(...[thunk, routerMiddleware(history)]), ...enhancers),
);

export default configureStore;