import config from '../config';

export const getGTApiToken = (token) : Promise<string> => {
    // const serverURL = `${config.apiBaseUrl}/getGTAccessToken?ssoToken=${token}`;
    const serverURL = `https://${config.publicHostname as string}/GetUserAccessToken`;
    // console.log("here " + serverURL);

    return fetch(serverURL, {
        method: "GET",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "content-Type": "application/json"
        })
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`HTTP error. Response status: ${response.status}`);
        }
        return response.json();
    })
    .then((data) => {
        //check govteams response and handle accordingly
        if (  data?.access_token !== '' )
            return(data?.access_token);

        throw new Error('HTTP error. Malformed response from API.');
    })
    .catch((err) => {
        console.log(err.message);
    })
    .finally(
    );
};


const fetchToken = (token, resolve) => {
    // const serverURL = `${config.apiBaseUrl}/getGTAccessToken?ssoToken=${token}`;
    const serverURL = `https://${config.publicHostname as string}/GetUserAccessToken`;
    console.log("here " + serverURL);

    fetch(serverURL, {
        method: "GET",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "content-Type": "application/json"
        })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error. Response status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            console.log(data);

            //check govteams response and handle accordingly
            resolve(data.access_token);
        })
        .catch((err) => {
            console.log(err.message);
        })
        .finally(
        );
};

export const getGTApiTokenAsync = (token) => {
    let promise = new Promise((resolve, reject) => {
        fetchToken(token, resolve);
    })
    
    return promise;
};