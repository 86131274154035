import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Modal } from '@fluentui/react/lib/Modal';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

import { progressActionCreators } from './Progress.operations';

import { isEmpty } from 'lodash';

export interface IProgressProps {
  isBlocking: boolean;
  isLoading: boolean;
  label: string;
  progress: any;
}

export class ProgressComponent extends React.Component<IProgressProps, {}> {
  public render() {
    return (
      <Modal
        isOpen={this.props.isLoading && !isEmpty(this.props.label)}
        isDarkOverlay={false}
        onDismiss={this.onDismiss}
        isBlocking={this.props.isBlocking}
        className='govTeams-progress-modal'
      >
        <div style={{paddingTop: '20px'}}>
          {this.props.isBlocking && (
            <Spinner
              size={SpinnerSize.large}
              label={this.props.label}
              ariaLive='assertive'
            />
          )}
          {!this.props.isBlocking && <div>{this.props.label}</div>}
        </div>
      </Modal>
    );
  }

  private onDismiss = () => {
    this.props.progress.endShowProgress();
  };
}

const mapStateToProps = (state: any) => ({
  isBlocking: state.progress.isBlocking,
  isLoading: state.progress.isLoading,
  label: state.progress.label
});

const mapDispatchToProps = (dispatch: any) => ({
  progress: bindActionCreators(progressActionCreators, dispatch)
});

export const Progress = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgressComponent);

export default Progress;
