import * as React from 'react';

/**
 * Fabric UI
 */
import { Link } from '@fluentui/react/lib/Link';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DirectionalHint } from '@fluentui/react/lib/Callout';


/**
 * Constants and utilities
 */
import * as constants from '../../../common/Constants';

export default class Tag extends React.Component<any, any> {

  public render() {
    const tag = !this.props.tag.name ? this.props.tag : this.props.tag.name;
    
    return (
      <TooltipHost
        content={tag}
        directionalHint={DirectionalHint.rightTopEdge}
      >
        <Link
          href={`${constants.SCREEN_SEARCH}/${tag.substr(1)}`}
          className="tag"
        >
          {tag.substring(0, 20)}
        </Link>
      </TooltipHost>
    );
  }
}
