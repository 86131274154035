import actions from './Notification.actions';

const initialState = {
  message: '',
  timestamp: '',
  show: false,
  notifications:
    sessionStorage.notifications && sessionStorage.notifications.length > 0
      ? JSON.parse(sessionStorage.notifications)
      : [],
  newCount:
    sessionStorage.newCount && parseInt(sessionStorage.newCount, 10) > 0
      ? parseInt(sessionStorage.newCount, 10)
      : 0
};

export const reducer = (state, action) => {
  state = state || initialState;

  let notifications = state.notifications;
  let newCount = state.newCount;

  switch (action.type) {
    case actions.NOTIFICATION_SHOW:
      notifications = state.notifications;
      newCount++;

      notifications.unshift({
        message: action.message,
        timestamp: action.timestamp,
        isNew: true
      });

      sessionStorage.newCount = newCount;
      sessionStorage.notifications = JSON.stringify(notifications);

      return {
        ...state,
        message: action.message,
        timestamp: action.timestamp,
        isNew: true,
        notifications,
        newCount,
        show: true
      };
    case actions.NOTIFICATION_HIDE:
      sessionStorage.notifications = JSON.stringify(notifications);

      return {
        ...state,
        message: '',
        notifications,
        show: false
      };
    case actions.NOTIFICATION_DISMISS:
      newCount--;
      notifications.forEach((notification) => {
        if (notification.timestamp === action.timestamp) {
          notification.isNew = false;
        }
      });

      sessionStorage.newCount = newCount;
      sessionStorage.notifications = JSON.stringify(notifications);

      return {
        ...state,
        message: '',
        notifications,
        newCount,
        show: false
      };
    case actions.NOTIFICATION_DISMISSALL:
      newCount = 0;
      notifications.forEach((notification) => {
        notification.isNew = false;
      });

      sessionStorage.newCount = newCount;
      sessionStorage.notifications = JSON.stringify(notifications);

      return {
        ...state,
        notifications,
        newCount
      };
    case actions.NOTIFICATION_DELETEALL:
      sessionStorage.newCount = 0;
      sessionStorage.notification = [];
      return {
        ...state,
        notifications: [],
        newCount: 0
      };
    default:
      return state;
  }
};

export default reducer;
